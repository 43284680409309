import React from 'react'
import {
  Row,
  Col,
  Container,
  FancyShow,
  RawHtml
} from '@kogk/common'
import styles from './MapEmbed.module.scss'
import cn from 'classnames'

export default ({ link }) => {
  const html = link ? link.embed : null

  return (
    <FancyShow>
      <Container fluid>
        <Row className='justify-content-center my-3 my-md-4'>
          <Col col={12} className={cn('px-0', styles.wrapper)}>
            <RawHtml html={html} />
          </Col>
        </Row>
      </Container>
    </FancyShow>
  )
}
